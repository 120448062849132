<label
  [for]="id()"
  [formGroup]="formGroup()"
  class="at-position-relative at-d-flex at-fw-400 at-align-items-center"
  [class]="inputClass()"
  [style.cursor]="disabled() ? 'not-allowed' : 'text'"
  [ngClass]="{
    'at-border-bottom':
      inputStyleType() === 'has-background' ||
      inputStyleType() === 'bottom-border',
    'at-border-secondary4':
      (inputStyleType() === 'has-background' ||
        inputStyleType() === 'bottom-border' ||
        inputStyleType() === 'full-border') &&
      !disabled() &&
      !errorMessage() &&
      !readonly(),
    'bottom-border': inputStyleType() === 'bottom-border',
    'has-background': inputStyleType() === 'has-background',
    'at-border at-rounded-2': inputStyleType() === 'full-border',
    'default at-py-4 at-px-2': inputSize() === 'default',
    'small at-p-2': inputSize() === 'small',
    'at-border-primary1': !readonly() && !disabled() ? focused : false,
    'show-placeholder': placeholder(),
    'at-border-failure': errorMessage(),
    'disabled at-border-secondary3': disabled(),
    'at-paragraph-small':
      inputStyleType() === 'has-background' && inputSize() === 'small',
  }">
  @if (startIcon()) {
    <label [for]="id()" class="at-d-flex">
      <img [src]="startIcon()" [alt]="label()" width="16" height="16" />
    </label>
  }

  <input
    class="input at-position-relative at-w-100 at-p-0 at-bg-transparent at-paragraph-regular at-border-unset at-appearance-none at-outline-unset"
    [formControlName]="inputFormControlName()"
    [style.cursor]="disabled() ? 'not-allowed' : 'text'"
    [id]="id()"
    [class.at-text-primary]="!errorMessage() && !disabled()"
    [class.at-text-failure]="errorMessage() && !disabled()"
    [class.at-text-secondary3]="disabled()"
    [class.at-bg-secondary5]="
      disabled() && inputStyleType() === 'has-background'
    "
    [class.start-icon-input]="startIcon()"
    [class.at-ps-2]="startIcon()"
    [class.disabled]="disabled()"
    [type]="type() ? type() : ''"
    [name]="name()"
    [min]="min()"
    [max]="max()"
    [attr.minlength]="minlength()"
    [attr.maxlength]="maxlength()"
    [checked]="checked()"
    [readonly]="readonly() || disabled()"
    [autocomplete]="autocomplete()"
    [value]="value()"
    [attr.inputmode]="inputMode()"
    [dir]="direction()"
    [placeholder]="
      !placeholder() ? label() + (required() ? ' *' : '') : placeholder()
    "
    (focus)="!readonly() && !disabled() ? (focused = true) : ''"
    (blur)="!readonly() && !disabled() ? (focused = false) : ''"
    (input)="onTyping()"
    (keypress)="
      type() === 'number'
        ? $event.charCode >= 48 && $event.charCode <= 57
        : true
    " />

  @if (label()) {
    <label
      [id]="id + '_label'"
      class="label at-text-hint"
      [style.cursor]="disabled() ? 'not-allowed' : 'text'"
      [class.at-text-secondary3]="disabled()"
      [class.start-icon-label]="startIcon()"
      [class.at-paragraph-regular]="disabled()"
      [class.at-paragraph-extra-small]="
        !disabled() && (focused || placeholder())
      "
      [class.at-text-hint]="!disabled() && focused && !placeholder()"
      [class.at-text-primary]="!disabled() && !focused && placeholder()"
      [class.at-bg-white]="!disabled() && focused"
      [class.at-px-1]="!disabled() && focused"
      [class.at-paragraph-mega-small]="
        inputStyleType() === 'has-background' && inputSize() === 'small'
      "
      [for]="id()">
      {{ label() + (required() ? ' *' : '') }}
    </label>
  }

  @if (endIcon()) {
    <button (click)="onEndIconCLick()">
      <label [for]="id()" class="at-d-flex">
        <img [src]="endIcon()" [alt]="label()" width="16" height="16" />
      </label>
    </button>
  }
</label>

@if (hintMessage() && !errorMessage()) {
  <div
    class="at-paragraph-extra-small at-text-hint at-mt-1 at-ms-2 at-text-start"
    [class.at-paragraph-secondary3]="disabled()">
    {{ hintMessage() }}
  </div>
}

@if (errorMessage()) {
  <div
    class="at-paragraph-extra-small at-text-failure at-mt-1 at-ms-2 at-text-start"
    [class.at-paragraph-secondary3]="disabled()">
    <pre>{{ errorMessage() }}</pre>
  </div>
}
