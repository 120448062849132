@if (label()) {
  <label [for]="id + '_label_0'" class="label">
    {{ label() }}
  </label>
}

@for (option of options(); track option; let idx = $index) {
  <label
    class="container"
    [ngClass]="{ disabled: disabled() }"
    [formGroup]="formGroup()">
    <input
      type="checkbox"
      class="checkbox"
      [formControlName]="inputFormControlName()"
      [attr.name]="inputFormControlName()"
      [value]="option.value"
      [id]="id + '_label_' + idx" />

    <span class="checkmark"></span>

    <div class="container__content">
      @if (option && option.label) {
        <div class="option_label">{{ option.label }}</div>
      }
      @if (option && option.description) {
        <div class="option_description">{{ option.description }}</div>
      }
    </div>
  </label>
}

@if (errorMessage()) {
  <div
    class="at-paragraph-extra-small at-text-failure at-mt-1 at-text-start"
    [class.at-paragraph-secondary3]="disabled()">
    {{ errorMessage() }}
  </div>
}
