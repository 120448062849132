import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  output,
  forwardRef,
  input,
  model,
  OnDestroy,
} from '@angular/core';
import {
  FormGroup,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { OptionItem } from '../lib.interface';
import { ClickOutSideDirective } from '../../directives/clickOutSide.directive';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'ecommerce-select',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    ClickOutSideDirective,
  ],
  templateUrl: './select.component.html',
  styleUrl: './select.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent implements AfterViewInit, OnDestroy {
  formGroup = input.required<FormGroup>();
  inputFormControlName = input.required<string>();
  id = input.required<string>();
  name = input.required<string>();
  startIcon = input<string>();
  endIcon = input<string>();
  inputClass = input<string>('');
  label = input<string>();
  disabled = input<boolean>();
  autofocus = input<boolean>();
  required = input<boolean>();
  placeholder = input<string>();
  direction = input<'rtl' | 'ltr'>();
  errorMessage = input<string>();
  hintMessage = input<string>();
  inputStyleType = input<'full-border' | 'bottom-border' | 'has-background'>(
    'full-border'
  );
  inputSize = input<'default' | 'small'>('default');
  options = input<OptionItem[]>([]);
  value = model<string | number>();
  selectedKeyValue = input<
    'id' | 'name' | 'alpha2code' | 'calling_code' | 'flag'
  >('id');
  selectedKeyName = input<
    'id' | 'name' | 'alpha2code' | 'calling_code' | 'flag'
  >('calling_code');
  hasSearch = input<boolean>(); // to add search option
  searchPlaceholder = input<string>('Search ...'); //to be able to translate or edit it
  noSearchResultLabel = input<string>('No search result'); //to be able to translate or edit it
  changeOption = output<OptionItem>();
  isDropdownOpen = false;
  selectedOption: OptionItem = <OptionItem>{};
  selectedValue?: string | number;
  focused?: boolean;
  notEmpty?: boolean;
  searchValue!: string;
  showNoSearchResult!: boolean;
  filteredOptions: OptionItem[] = [];
  removeLabelWhenSelectValue = input<boolean>(false);
  private destroy$ = new Subject<void>();

  controlName() {
    return this.formGroup()?.controls[this.inputFormControlName()];
  }

  toggleDropdown() {
    this.filteredOptions = [];
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  ngAfterViewInit(): void {
    if (this.controlName()) {
      this.controlName()
        .valueChanges.pipe(takeUntil(this.destroy$))
        .subscribe(value => {
          this.writeValue(value, false);
        });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  writeValue(value: string | number, updateFormControlValue?: boolean) {
    if (value && this.options()) {
      const match = this.options().find((item: OptionItem) => {
        return item[this.selectedKeyValue()] === value;
      });
      if (match) {
        this.selectOption(match, updateFormControlValue);
      }
    }
  }

  selectOption(item: OptionItem, updateFormControlValue?: boolean) {
    if (item) {
      this.selectedOption = item;
      this.value.set(item[this.selectedKeyValue()]);
      if (updateFormControlValue) {
        this.controlName().patchValue(this.value());
        this.formGroup().markAsDirty();
      }
      this.filteredOptions = [];
      this.selectedValue = item[this.selectedKeyName()];
      this.changeOption.emit(item);
      this.notEmpty = true;
    }
  }

  onDropdownSearch(event: Event) {
    if (event.target) {
      const searchValue = (<HTMLInputElement>event.target).value;
      if (this.options()) {
        if (searchValue) {
          this.filteredOptions = this.options().filter((item: OptionItem) => {
            return item.name
              .toLocaleLowerCase()
              .includes(searchValue.toLocaleLowerCase());
          });
          if (!this.filteredOptions.length) {
            this.showNoSearchResult = true;
          } else {
            this.showNoSearchResult = false;
          }
        } else {
          this.filteredOptions = [];
        }
      } else {
        this.showNoSearchResult = true;
      }
    }
  }

  clickedOutside() {
    this.isDropdownOpen = false;
  }
}
