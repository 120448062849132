import { CommonModule } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'ecommerce-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule],
})
export class InputComponent {
  formGroup = input.required<FormGroup>();
  inputFormControlName = input.required<string>();
  id = input.required<string>();
  type = input.required<string>();
  name = input.required<string>();
  startIcon = input<string>();
  endIcon = input<string>();
  inputClass = input<string>('');
  label = input<string>();
  placeholder = input<string>();
  min = input<number | string>();
  max = input<number | string>();
  minlength = input<number>();
  maxlength = input<number>();
  checked = input<boolean>();
  disabled = input<boolean>();
  readonly = input<boolean>();
  value = input<string>('');
  autocomplete = input<string | boolean>('on');
  required = input<boolean>();
  inputMode = input<string>();
  direction = input<'rtl' | 'ltr'>();
  errorMessage = input<string>();
  hintMessage = input<string>();
  inputStyleType = input<'full-border' | 'bottom-border' | 'has-background'>(
    'full-border'
  );
  inputSize = input<'default' | 'small'>('default');
  endIconCLick = output<void>();
  typing = output<string>();
  focused?: boolean;

  controlName() {
    return this.formGroup()?.controls[this.inputFormControlName()];
  }

  onEndIconCLick() {
    this.endIconCLick.emit();
  }

  onTyping() {
    this.typing.emit(this.controlName().value);
  }
}
