import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ToggleOption } from '../lib.interface';

@Component({
  selector: 'ecommerce-checkbox',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent {
  id = input.required<string>();
  formGroup = input.required<FormGroup>();
  inputFormControlName = input.required<string>();
  label = input<string>();
  disabled = input<boolean>(false);
  options = input<ToggleOption[]>([]);
  errorMessage = input<string>();
}
